import React, { Component } from 'react'
import './display/dotdot'
import Spinner from './display/spinner'
import Table from './display/table'
class Report extends Component {
    state = { data: [], init: false }
    componentDidMount() {
        this.calculate()
    }
    async calculate() {
        let points = []
        let { payments, sales, expenses, data } = this.props
        let { type, dateA = new Date(1970), dateB = new Date(), allDate, aPlats, aTers, aBuys, selectedTerritories = [], selectedBuyers = [], selectedPlatforms = [] } = data
        console.log(data)
        if (aPlats) {
            if (type === 'expense') {
                selectedPlatforms = [...(expenses?.reduce((a, b) => {
                    if (b.platform && !a.includes(b.platform)) a.push(b.platform)
                    return a
                }, []) || []), ...(window.app.state.companyList?.filter(a => a.crm === 'film-distribution').map(u => u._id) || [])]
            } else {
                selectedPlatforms = [...(payments?.reduce((a, b) => {
                    if (b.platform && !a.includes(b.platform)) a.push(b.platform)
                    return a
                }, []) || []), ...(window.app.state.companyList?.filter(a => a.crm === 'film-distribution').map(u => u._id) || [])]

            }

        }
        if (aTers) {
            selectedTerritories = [...(sales?.reduce((a, b) => {
                if (b.territory && !a.includes(b.territory)) a.push(b.territory)
                return a
            }, []) || []), ...window.territories]
        }
        if (aBuys) {
            selectedBuyers = [...(sales?.reduce((a, b) => {
                if (b.buyer && !a.includes(b.buyer)) a.push(b.buyer)
                return a
            }, []) || []), ...(window.app.state.companyList?.filter(a => a.crm === 'film-sales').map(u => u._id) || [])]
        }
        let checkDateRange = time => {
            let x = new Date(dateA).getTime()
            let y = new Date(dateB).getTime()
            let t = new Date(time).getTime()
            return allDate ? true : (t && ((x && y) ? ((t > x) && (t < y)) : x ? (t > x) : y ? (t < y) : true))
        }
        switch (type) {
            case 'sale-buyer': {
                let data = sales.reduce((a, b) => {
                    if ((aBuys || selectedBuyers.length < 1) || selectedBuyers.includes(b.buyer)) {
                        if (!a[b.buyer]) a[b.buyer] = []
                        if (!allDate && (dateA || dateB)) {
                            console.log(window.formatDate(b.date), checkDateRange(b.date))
                            if (checkDateRange(b.date)) a[b.buyer].push(b)
                        } else {
                            a[b.buyer].push(b)
                        }
                    }
                    return a
                }, {})
                let keys = Object.keys(data)
                for (let i = 0; i < keys.length; i++) {
                    let d = data[keys[i]]
                    let point = {
                        buyer: window.getCompany(keys[i]),
                        data: d
                    }
                    point.received_total = window.sum(d.map(u => u ? (u.received_amount || 0) : 0))
                    point.agreement_total = window.sum(d.map(u => u ? (u.agreement_amount || 0) : 0))
                    points.push(point)
                }
                let missed = selectedBuyers?.filter(a => !keys.includes(a)) || []
                for (let i = 0; i < missed.length; i++) points.push({ buyer: window.getCompany(missed[i]), data: [], received_total: 0, agreement_total: 0 })
                break
            }
            case 'sale-territory': {
                let data = sales.reduce((a, b) => {
                    if ((aTers || selectedTerritories.length < 1) || selectedTerritories.includes(b.territory)) {
                        if (!a[b.territory]) a[b.territory] = []
                        if (!allDate && (dateA || dateB)) {
                            if (checkDateRange(b.date)) a[b.territory].push(b)
                        } else {
                            a[b.territory].push(b)
                        }
                    }
                    return a
                }, {})
                let keys = Object.keys(data)
                for (let i = 0; i < keys.length; i++) {
                    let d = data[keys[i]]
                    let point = {
                        territory: keys[i],
                        data: d
                    }
                    point.received_total = window.sum(d.map(u => u ? (u.received_amount || 0) : 0))
                    point.agreement_total = window.sum(d.map(u => u ? (u.agreement_amount || 0) : 0))
                    points.push(point)
                }
                let missed = selectedTerritories?.filter(a => !keys.includes(a)) || []
                for (let i = 0; i < missed.length; i++) points.push({ territory: missed[i], data: [], received_total: 0, agreement_total: 0 })
                break
            }
            case 'expense': {
                let data = expenses.reduce((a, b) => {
                    if ((aPlats || selectedPlatforms.length < 1) || selectedPlatforms.includes(b.platform)) {
                        if (!a[b.platform]) a[b.platform] = []
                        if (!allDate && (dateA || dateB)) {
                            if (checkDateRange(b.date)) a[b.platform].push(b)
                        } else {
                            a[b.platform].push(b)
                        }
                    }
                    return a
                }, {})
                let keys = Object.keys(data)
                for (let i = 0; i < keys.length; i++) {
                    let d = data[keys[i]]
                    let point = {
                        platform: window.getCompany(keys[i]),
                        data: d
                    }
                    point.expense = Math.abs(window.sum(d.map(u => u ? (u.income || 0) : 0)))
                    point.amount = Math.abs(window.sum(d.map(u => u ? (u.amount || 0) : 0)))
                    points.push(point)
                }
                let missed = selectedPlatforms?.filter(a => !keys.includes(a)) || []
                for (let i = 0; i < missed.length; i++) points.push({ platform: window.getCompany(missed[i]), data: [], expense: 0, amount: 0 })
                break
            }
            case 'payment': {
                let data = payments.reduce((a, b) => {
                    if ((aBuys || selectedPlatforms.length < 1) || selectedPlatforms.includes(b.platform)) {
                        if (!a[b.platform]) a[b.platform] = []
                        if (!allDate && (dateA || dateB)) {
                            if (checkDateRange(b.date)) a[b.platform].push(b)
                        } else {
                            a[b.platform].push(b)
                        }
                    }
                    return a
                }, {})
                let keys = Object.keys(data)
                for (let i = 0; i < keys.length; i++) {
                    let d = data[keys[i]]
                    let point = {
                        platform: window.getCompany(keys[i]),
                        data: d
                    }
                    point.income = window.sum(d.map(u => u ? (u.income || 0) : 0))
                    point.amount = window.sum(d.map(u => u ? (u.amount || 0) : 0))
                    points.push(point)
                }
                let missed = selectedPlatforms?.filter(a => !keys.includes(a)) || []
                for (let i = 0; i < missed.length; i++) points.push({ platform: window.getCompany(missed[i]), data: [], income: 0, amount: 0 })
                break
            }
            default: break
        }
        return this.setState({ data: points, init: true })
    }
    sort(name) {
        if (name === 'received_total' || name === 'agreement_total' || name === 'deal_count' || 'received_amount' || 'agreement_amount' || 'amount' || 'income') {
            return (a, b) => b[name] - a[name]
        } else if (name === 'date') {
            return (a, b) => new Date(a[name]).getTime() - new Date(b[name]).getTime()
        } else if (name === 'buyer' || name === 'platform') {
            return (a, b) => window.nameCompare(window.getCompany(a[name]), window.getCompany(b[name]))
        } else if (name === 'title') {
            return (a, b) => {
                let c = a[name]
                let d = b[name]
                let e = c ? window.app.state.titles?.find(u => u._id === c) : null
                let f = d ? window.app.state.titles?.find(u => u._id === d) : null
                return window.nameCompare(e ? e.title : e, f ? f.title : f)
            }
        } else {
            return (a, b) => a[name] < b[name] ? -1 : a[name] === b[name] ? 0 : 1
        }
    }
    renderFunc = (name) => {
        if (name === 'received_total' || name === 'agreement_total' || name === 'income' || name === 'amount' || name === 'expense' || name === 'agreement_amount' || name === 'received_amount') {
            return (text, record) => <>${window.currency(text)}</>
        } else if (name === 'date') {
            return (text, record) => <>{window.formatDate(text)}</>
        } else if (name === 'title') {
            return (text, record) => <>{window.app.state.titles?.find(a => a._id === record)?.title || text}</>
        } else if (name === 'platform' || name === 'buyer') {
            return (text, record) => <>{window.getCompany(text)}</>
        } else {
            return (text, record) => <>{text}</>
        }
    }
    export() {
        fetch('https://crm.octanemultimedia.com/run-report', { method: 'post', headers: { authorization: window.app.state.auth, 'Content-Type': 'application/json' }, body: JSON.stringify({ data: this.state.data }) }).then(r => r.json()).then(({ error, message, link }) => {
            if (error || !link) return window.flash(message || error)
            let a = document.createElement('a')
            a.href = link
            a.target = '_blank'
            document.querySelector('body').append(a)
            a.click()
            a.remove()
        }).catch(e => window.flash(e))
    }
    commas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    columns() {
        let d = (title, index, render, sorter) => ({ title, dataIndex: index, render: render || this.renderFunc(index), sorter: sorter || this.sort(index) })
        return Object.values(this.state.data.reduce((a, b) => {
            let k = Object.keys(b).filter(a => !['data', '_id', 'id'].includes(a))
            k.forEach(u => {
                if (!a[u]) a[u] = d(window.parseKey(u), u)
            })
            return a
        }, {}))
    }
    render() {
        let mainKey = this.props.mainKey || (this.state.data ? this.state.data[0] && Object.keys(this.state.data[0])[0] : '_id')
        window.report = this
        let columns = this.columns()
        if (!this.state.init) return (<div className="b1"><h3>Creating Report<dot-dot></dot-dot></h3><Spinner></Spinner></div>)
        return (<div className="b1">
            <div id="tempButs">
                <button onClick={e => {
                    this.export()
                }}>Export CSV</button>
            </div>
            <h2>{window.capitalize(this.props.data?.type)} Report</h2>
            {this.props.dateRange && <span>From: {window.formatDate(this.props.dateRange['a'])} - To: {window.formatDate(this.props.dateRange['b'])}</span>}
            <div className="rel b1">
                <div id="fixedtoggles" className="b2 jfe" style={{ top: 0, right: 0, position: 'absolute', zIndex: 10 }}>
                    <button className={'fixedtoggle' + (this.state.mode !== 'table' ? '' : ' active')} onClick={e => this.setState({ mode: 'table' })}>Table</button>
                    <button className={'fixedtoggle' + (this.state.mode !== 'grid' ? '' : ' active')} onClick={e => this.setState({ mode: 'grid' })}>Grid</button>
                </div>
                {(this.state.mode === 'grid' ? <div className="c3" style={{ maxWidth: '80%' }}>
                    {this.state.data.map((u, i) => (<div className="datapoint" key={i}>
                        {Object.keys(u).filter(a => !['data', '_id', 'visible', '__v'].includes(a)).map((a, i) => (<div key={i}>{a.toUpperCase()} : {u[a]}</div>))}
                    </div>))}
                </div> : (<Table
                    className="table"
                    style={{ overflowX: "auto" }}
                    columns={columns}
                    data={this.state.data}
                    mainKey={mainKey}
                    expandable={{
                        expandedRowRender: ({ data = [] }, rowIndex) => {
                            let columns = () => {
                                let d = (title, index, render, sorter) => ({ title, dataIndex: index, render: render || this.renderFunc(index), sorter: sorter || this.sort(index) })
                                return Object.values(data.reduce((a, b) => {
                                    let k = Object.keys(b).filter(a => !['data', '_id', 'visible', '__v', 'title'].includes(a))
                                    k.forEach(u => {
                                        if (!a[u]) a[u] = d(window.parseKey(u), u)
                                    })
                                    return a
                                }, {}))
                            }
                            return (<Table key={rowIndex}
                                style={{ overflowX: "auto" }}
                                columns={columns() || Object.keys(data[0]).map((u) => ({ title: window.parseKey(u), dataIndex: u, render: text => <>{text}</> }))}
                                data={data}
                                mainKey={'_id'}
                                rowClassName={'pointer'}
                                onRow={(record) => ({ onClick: () => this.setState({ viewing: record._id, edit: true, ptype: window.app.state.sales?.find(a => a._id === record._id) ? 'sale' : window.app.state.payments?.find(a => a._id === record._id) ? (window.app.state.payments?.find(a => a._id === record._id).amount >= 0 ? 'payment' : 'expense') : '' }) })}
                            />)
                        }
                    }}
                />))}
            </div>
        </div>)
    }
}
export default Report