
import React, { Component } from 'react'
class DetailedCountDown extends Component {
    state = { remaining: 0 }
    componentDidMount() {
        this.setState({ remaining: new Date(this.props.expires).getTime() - new Date().getTime() }, () => this.timer = setInterval(this.setRemaining, 1000))
    }
    componentDidUpdate({expires}) {
        if (expires !== this.props.expires) this.setState({ remaining: new Date(this.props.expires).getTime() - new Date().getTime() })
    }
    componentWillUnmount() {
        clearInterval(this.timer)
    }
    setRemaining = () => {
        if (this.props.expires) this.setState({ remaining: new Date(this.props.expires).getTime() - new Date().getTime() })
    }
    render() {
        let { remaining } = this.state
        if (remaining < 1) return <div>Expired</div>
        let years = Math.floor(remaining / (1000 * 60 * 60 * 24 * 365))
        let months = Math.floor(remaining % (1000 * 60 * 60 * 24 * 365) / (1000 * 60 * 60 * 24 * 30))
        let weeks = Math.floor(remaining % (1000 * 60 * 60 * 24 * 365) % (1000 * 60 * 60 * 24 * 30) / (1000 * 60 * 60 * 24 * 7))
        let days = Math.floor(remaining % (1000 * 60 * 60 * 24 * 365) % (1000 * 60 * 60 * 24 * 30) % (1000 * 60 * 60 * 24 * 7) / (1000 * 60 * 60 * 24))
        let hours = Math.floor(remaining % (1000 * 60 * 60 * 24 * 365) % (1000 * 60 * 60 * 24 * 30) % (1000 * 60 * 60 * 24 * 7) % (1000 * 60 * 60 * 24) / (1000 * 60 * 60))
        let minutes = Math.floor(remaining % (1000 * 60 * 60 * 24 * 365) % (1000 * 60 * 60 * 24 * 30) % (1000 * 60 * 60 * 24 * 7) % (1000 * 60 * 60 * 24) % (1000 * 60 * 60) / (1000 * 60))
        let seconds = Math.floor(remaining % (1000 * 60 * 60 * 24 * 365) % (1000 * 60 * 60 * 24 * 30) % (1000 * 60 * 60 * 24 * 7) % (1000 * 60 * 60 * 24) % (1000 * 60 * 60) % (1000 * 60) / (1000))
        let y = () => <>{years ? `${years} Years${months || weeks || days || hours || minutes || seconds ? ' : ' : ''}` : ''}</>
        let m = () => <>{months ? `${months} Months${weeks || days || hours || seconds || minutes ? ' : ' : ''}` : ''}</>
        let w = () => <>{weeks ? `${weeks} Weeks${days || hours || seconds ? ' : ' : ''}` : ''}</>
        let d = () => <>{days ? `${days} Days${hours || minutes || seconds ? ' : ' : ''}` : ''}</>
        let h = () => <>{hours ? `${hours} Hours${minutes || seconds ? ' : ' : ''}` : ''}</>
        let mins = () => <>{minutes ? `${minutes} Minutes${seconds ? ' : ' : ''}` : ''}</>
        let s = () => <>{seconds ? `${seconds} Seconds` : ''}</>
        return (<div>{y()}{m()}{w()}{d()}{h()}{mins()}{s()}</div>)
    }
}
class CountDown extends Component {
    state = { remaining: 0 }
    componentDidMount() {
        if (this.props.expires) { this.setState({ remaining: new Date(this.props.expires).getTime() - new Date(this.props.starts || new Date()).getTime() }, () => { if (!this.props.noCount) { this.timer = setInterval(this.setRemaining, 1000) } }); this.expires = this.props.expires }
    }
    expires = ''
    componentDidUpdate() {
        let { expires } = this.props
        if (expires !== this.expires) {
            this.expires = expires
            let valid = !isNaN(Date.parse(this.expires))
            if (!valid && this.timer) {
                this.setState({ remaining: 0 })
                clearInterval(this.timer)
            } else if (valid && !this.timer) {
                if (!this.props.noCount) this.timer = setInterval(this.setRemaining, 1000)
                this.setRemaining()
            } else if (this.state.remaining) {
                this.setState({ remaining: 0 })
            }
        }
    }
    componentWillUnmount() {
        clearInterval(this.timer)
    }
    setRemaining = () => {
        if (this.props.expires) this.setState({
            remaining: new Date(this.props.expires).getTime() - new Date(this.props.starts || new Date()).getTime()
        })
    }
    render() {
        let { remaining } = this.state
        if (remaining < 1) return <div>{this.props.noCount ? 'N/A' : this.props.since ? (() => {
            let remaining = new Date(this.props.starts || new Date()).getTime() - new Date(this.props.expires).getTime()
            let years = Math.floor(remaining / (1000 * 60 * 60 * 24 * 365))
            let months = Math.floor(remaining % (1000 * 60 * 60 * 24 * 365) / (1000 * 60 * 60 * 24 * 30))
            let weeks = Math.floor(remaining % (1000 * 60 * 60 * 24 * 365) % (1000 * 60 * 60 * 24 * 30) / (1000 * 60 * 60 * 24 * 7))
            let days = Math.floor(remaining % (1000 * 60 * 60 * 24 * 365) % (1000 * 60 * 60 * 24 * 30) % (1000 * 60 * 60 * 24 * 7) / (1000 * 60 * 60 * 24))
            if (!days && !months && !weeks && !years) return this.props.endText
            let y = () => years ? `${years} Year${years > 1 ? 's' : ''}${months || weeks || days ? ' : ' : ''}` : ''
            let m = () => months ? `${months} Months${weeks || days ? ' : ' : ''}` : ''
            let w = () => weeks ? `${weeks} Weeks${days ? ' : ' : ''}` : ''
            let d = () => days ? `${days} Days` : ''
            return (this.props.endText || 'Expired') + ' ' + (y()+m()+w()+d()) + ' Ago'
        })() : this.props.endText || 'Expired'}</div>
        let years = Math.floor(remaining / (1000 * 60 * 60 * 24 * 365))
        let months = Math.floor(remaining % (1000 * 60 * 60 * 24 * 365) / (1000 * 60 * 60 * 24 * 30))
        let weeks = Math.floor(remaining % (1000 * 60 * 60 * 24 * 365) % (1000 * 60 * 60 * 24 * 30) / (1000 * 60 * 60 * 24 * 7))
        let days = Math.floor(remaining % (1000 * 60 * 60 * 24 * 365) % (1000 * 60 * 60 * 24 * 30) % (1000 * 60 * 60 * 24 * 7) / (1000 * 60 * 60 * 24))
        if (!days && !months && !weeks && !years) return <div>{this.props.endText}</div>
        let y = () => <>{years ? `${years} Year${years > 1 ? 's' : ''}${months || weeks || days ? ' : ' : ''}` : ''}</>
        let m = () => <>{months ? `${months} Months${weeks || days ? ' : ' : ''}` : ''}</>
        let w = () => <>{weeks ? `${weeks} Weeks${days ? ' : ' : ''}` : ''}</>
        let d = () => <>{days ? `${days} Days` : ''}</>
        return (<div>{y()}{m()}{w()}{d()}</div>)
    }
}
export default CountDown