import React, { useState } from 'react'
function LogIn(props) {
  let screenCaps = (Math.min(Math.max(220, window.innerWidth / 8), 390)) + 'px'
  let [isTitle, setIsTitle] = useState(!localStorage.getItem('loginType'))
  let titles = props?.titles || []
  return (<>
    <video id="loginVideo" src={window.API + "/static/bg.mp4"} autoPlay={true} loop={true} muted={true}></video>
    <div id="loginCont">
      <div id="loginHold" className="jfs">
        <form className="b1" onSubmit={e => {
          e.preventDefault()
          let form = e.target
          let email = form['email']?.value
          let password = form['password']?.value
          let title = form['title']?.value
          let flash = e => {
            window.flash(e)
            let warn = form.querySelector('.warn')
            if (warn) {
              warn.innerHTML = e
              setTimeout(() => warn.innerHTML = '', 3000)
            }
          }
          if (!password) return flash('Password is required')
          if (!email && !title) return flash(`${!isTitle ? 'Email' : 'Title ID'} is required`)
          window.app.request('/login', 'post', { email, password, title }).then(result => {
            let { auth, userID, type, redirect } = result
            localStorage.setItem('auth', auth)
            localStorage.setItem('userID', userID)
            localStorage.setItem('type', type)
            window.location = redirect
          }).catch(e => {
            window.flash(e)
          })
        }}>
          <big-logo color="white" style={{ maxWidth: screenCaps, width: screenCaps }}></big-logo>
          <div className="b1">
            <span><strong>Select Login Type:</strong></span>
            <div className="b2 wrap">
              <span style={isTitle ? { color: 'var(--p)', textDecoration: 'underline', cursor: 'pointer' } : { cursor: 'pointer' }} onClick={e =>{ localStorage.removeItem('loginType'); setIsTitle(true)}}>TITLE</span>
              <span style={!isTitle ? { color: 'var(--p)', textDecoration: 'underline', cursor: 'pointer' } : { cursor: 'pointer' }} onClick={e => {localStorage.setItem('loginType', 'user'); setIsTitle(false)}}>USER</span>
            </div>
          </div>
          <span className="warn"></span>
          {isTitle ? <>
            <div className="b1">
              <span className="fable jsfs">Title ID</span>
              <input className="fancyInput" type="text" name="title"></input>
            </div>
            <div className="b1">
              <span className="fable jsfs">Password</span>
              <div className="rel">
                <input className="fancyInput" type="password" style={{ maxWidth: screenCaps, width: screenCaps }} name="password" placeholder="Password"></input>
                <i style={{ position: 'absolute', right: '5px', top: '33%', color: 'var(--p)' }} className="fa fa-eye" onMouseEnter={e => e.target.parentElement.querySelector('input').type = 'text'} onMouseLeave={e => e.target.parentElement.querySelector('input').type = 'password'}></i>
              </div>
            </div>
          </> : <>
            <div className="b1">
              <span className="fable jsfs">Email Address</span>
              <input className="fancyInput" type="email" style={{ maxWidth: screenCaps, width: screenCaps }} name="email" placeholder="Email"></input>
            </div>
            <div className="b1">
              <span className="fable jsfs">Password</span>
              <div className="rel">
                <input className="fancyInput" type="password" style={{ maxWidth: screenCaps, width: screenCaps }} name="password" placeholder="Password"></input>
                <i style={{ position: 'absolute', right: '5px', top: '33%', color: 'var(--p)' }} className="fa fa-eye" onMouseEnter={e => e.target.parentElement.querySelector('input').type = 'text'} onMouseLeave={e => e.target.parentElement.querySelector('input').type = 'password'}></i>
              </div>
            </div>
            <span onClick={e => {
              window.redirect('/reset-password')
            }} style={{ fontSize: '.9rem', color: 'var(--p)', textAlign: 'right', margin: '0', marginTop: '-10px', marginBottom: '5px', width: '100%', padding: '0', cursor: 'pointer' }}>Forgot Password</span>
          </>}
          <button>Log In</button>
        </form>
      </div>
    </div>
  </>)
}
export default LogIn