import React, { Component } from 'react'
import SearchBar from './searchbar'
class Header extends Component {
    state = { openMenu: false }
    render() {
        return (<header>
            <div id="logo">
                <logo-icon onClick={() => window.redirect('/')} style={{ cursor: 'pointer' }}></logo-icon>
            </div>
            <div id="takeAway" style={{ maxWidth: '100%', color: 'var(--g)' }}>
                <div className="b2" style={{ margin: 0 }}><span style={{ fontSize: '2rem' }}>"</span><span>{this.props.quote?.text || 'Movies going around the world...'}</span><span style={{ fontSize: '2rem' }}>"</span></div>
                <span style={{ color: 'var(--l)', fontSize: '.8rem', fontWeight: 800, textAlign: 'right', width: '50%' }}>- {this.props.quote?.author || "Quote Author"}</span>
            </div>
            <div id="headSearch" className="b1" style={{ padding: '10px 0', overflow: 'visible' }}>
                <SearchBar items={this.props.titles} indexes={['title', 'titleAKA']} mainKey={'_id'} template={(u, i) => (<div key={i} className="b2 jsb searchResult">
                    <h3>{u.title}</h3>
                    <button onClick={e => window.redirect(`/title?_id=${u._id}`)}>View</button>
                </div>)} placeholder={'Search Movies'}></SearchBar>
            </div>
            <div id="topAccountOptions" onMouseEnter={() => {
                if (!this.state.openMenu) this.setState({ openMenu: true })
            }} onMouseLeave={() => {
                if (this.state.openMenu) this.setState({ openMenu: false })
            }} className="b2 rel" style={{ color: 'var(--l)' }}>
                <i style={{ color: 'var(--p)', cursor: 'pointer' }} className="far fa-user fa-2x"></i>
                <span onMouseEnter={() => {
                    if (!this.state.openMenu) this.setState({ openMenu: true })
                }}>{this.props.name}</span>
                <i onMouseEnter={() => {
                    if (!this.state.openMenu) this.setState({ openMenu: true })
                }} style={{ color: 'var(--p)' }} className="fas fa-angle-down"></i>
                <div id="floatOpt" className={this.state.openMenu ? '' : 'hidden'}>
                    <button onClick={window.logOut}>Log Out</button>
                </div>
            </div>
            <div id="mobileToggle" onClick={() => window.app.setState({ showMobileHeader: !window.app.state.showMobileHeader })}><i className="fa fa-bars fa-2x"></i></div>
        </header>)
    }
}
export default Header