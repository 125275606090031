import React, { Component } from 'react'
import * as JsSearch from 'js-search';
class SearchBar extends Component {
    state = {
        list: [],
        searching: ''
    }
    componentDidMount() {
        this.setState({ key: this.props.mainKey || '_id' })
        if (typeof this.props.bar === 'function') this.props.bar(this)
    }
    componentDidUpdate() {
        if (this.props.mainKey !== this.state.key) this.setState({ key: this.props.mainKey })
        if (typeof this.props.bar === 'function') this.props.bar(this)
    }
    find = (i) => {
        let s = new JsSearch.Search(this.state.key);
        s.indexStrategy = new JsSearch.PrefixIndexStrategy()
        s.addIndex(this.props.indexes[i])
        return s
    }
    search(value) {
        let {items, indexes} =  this.props
        if (!this.props.items || this.props.items.length < 1) return []
        let a = []
        if (this.props.indexes?.length > 1) {
            for (let i = 0; i < this.props.indexes.length; i++) {
                let s = this.find(i)
                s.addDocuments(this.props.items)
                a.push({ key: this.props.indexes[i], results: s.search(value) })
            }
        } else {
            let s = this.find(0)
            s.addDocuments(this.props.items)
            a = s.search(value)
        }
        if (typeof this.props.onSearch === 'function') this.props.onSearch(value)
        return a
    }
    list = (u, i) => {
        let single = (u, i) => (<div key={i} className="b2 jsb searchResult">
        <h3>{u[this.state.key]}</h3>
        <button id={u[this.state.key]} className="smallbut" onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            if (this.props.callback && typeof this.props.callback === 'function') this.props.callback(e.target.id)
        }}>VIEW</button></div>)
        if (this.props.indexes?.length > 1) {
            return <ul>
                <li><strong>{u.key}</strong></li>
                {u.results.map((u,i) => (<li key={i}>{single(u, i)}</li>))}
            </ul>
        } else {
            return single(u,i)
        }
    }
    render() {
        return (<div id="searchbar" className="rel">
            <i style={{ color: 'var(--p)', transition: '500ms ease', position: 'absolute', right: '10px', marginRight: (!this.state.focused ? '0' : '100%'), top: '0' }} className="fab fa-searchengin fa-2x"></i>
            <input id="searchbarInput"  onFocus={() => this.setState({ focused: true })} onBlur={e => {
                    if (!e.currentTarget.contains(e.relatedTarget)) this.setState({ focused: false })
                }} placeholder={this.props.placeholder || "Search"} type="text" autoComplete="off" value={this.state.searching} onChange={e => this.setState({ list: this.search(e.target.value), searching: e.target.value })}></input>
            <div id="searchresults" className={'b1' + (!this.state.searching ? ' hidden' : '')}>
                <div className="b1 jsa">Search Results</div>
                {this.state.list.length < 1 ? (<h3 style={{ color: 'var(--l)' }}>No Results for {this.state.searching || 'Null'}</h3>) : this.state.list.map(this.props.template ? (u,i) => u.results.map(this.props.template) : this.list)}
            </div>
        </div>)
    }
}
export default SearchBar