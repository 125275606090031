import { withRouter, Link, Route, Switch } from 'react-router-dom';
import { Component } from 'react'
import Header from './header'
import Title from './title'
import LogIn from './login'
import ResetPass from './resetpass'
import DotDot from './display/dotdot'
import Spinner from './spinner';
import PageTitle from './page-title'
class App extends Component {
  init = () => {
    let { location } = this.props, auth = localStorage.getItem('auth'), type = localStorage.getItem('type')
    location = location.pathname
    let state = {
      auth,
      userID: localStorage.getItem('userID'),
      location: '/',
      type,
      title: localStorage.getItem('title'),
      titles: [],
      sidebarContents: auth ? (type === 'title' ? [] : [
        { name: 'Dashboard', link: '/', icon: <i className="fas fa-home fa-1x"></i> },
        // { name: 'Movies', link: '/film', icon: <i className="fas fa-film fa-2x"></i> }
      ]) : [],
      queries: this.queries(),
      companyList: []
    }
    return state
  }
  state = this.init()
  componentDidMount() {
    window.app = this
    this.setState({ 'location': this.props.location?.pathname, queries: this.queries() }, () => {
      if (!this.state.auth && !(this.state.location === '/login' || this.state.location === '/reset-password')) {
        window.redirect('/login')
      } else if (this.state.auth && (this.state.location === '/login' || this.state.location === '/reset-password')) {
        window.redirect('/')
      } else if (this.state.auth && (this.state.location === '/title') && !this.state.title && this.state.queries['_id']) {
        this.setState({ title: this.state.queries['_id'] })
      }
    })
    if (this.state.auth) {
      if ((this.state.queries['_id'] && this.state.location === '/title') && !this.state.titles?.find(a => a._id === this.state.queries['_id']) && !this.gettingTitle) {
        this.gettingTitle = true
        this.getTitle(this.state.queries['_id']).then(() => {
          this.gettingTitle = false
        }).catch(e => {
          window.flash(e)
        })
      } else if (this.state.type === 'title' && this.state.title !== this.state.userID) {
        this.setState({ title: this.state.userID }, () => { window.redirect(`/title?_id=${this.state.title}`) })
      }
    }
    this.initial()
  }
  componentDidUpdate() {
    let queries = this.queries()
    if (this.state.location !== this.props.location?.pathname) {
      this.setState({ location: this.props.location?.pathname, queries }, () => {
        if (!this.state.auth && !(this.state.location === '/login' || this.state.location === '/reset-password')) { window.redirect('/login') } else if (this.state.auth && (this.state.location === '/login' || this.state.location === '/reset-password')) {
          window.redirect('/')
        } else if (this.state.auth && (this.state.location === '/title') && !this.state.title && this.state.queries['_id']) {
          this.setState({ title: this.state.queries['_id'] })
        }
      })
    } else if (this.state.queries?._id !== queries?._id) {
      this.setState({ queries }, () => {
        if (this.state.auth && (this.state.location === '/title') && this.state.title !== this.state.queries['_id']) {
          this.setState({ title: this.state.queries['_id'] })
        }
      })
    }
    if (this.state.auth && !this.state.inited) this.initial()
    if (this.state.auth) {
      if ((this.state.queries['_id'] && this.state.location === '/title') && !this.state.titles?.find(a => a._id === this.state.queries['_id']) && !this.gettingTitle) {
        this.gettingTitle = true
        this.getTitle(this.state.queries['_id']).then(() => {
          this.gettingTitle = false
        }).catch(e => {
          window.flash(e)
        })
      }
    }
  }
  handleSocket = (type, data, error) => {
    if (!type || !data) return
    if (error) return window.flash(error)
    switch (type) {
      case 'title': {
        this.getTitle(data._id, true).then(() => {

        }).catch(e => window.flash(e))
        break
      }
      case 'payment': {
        if (data instanceof Array) {
          let title = data?.[0].title
          if (title) {
            this.setState({
              titles: this.state.titles?.map(a => {
                if (a._id === title) {
                  let payments = data.filter(b => b.title === title)
                  return ({
                    ...a, payments: [...[...a.payments].map(u => {
                      let k = payments.splice(payments.findIndex(b => b._id === u._id), 1)
                      if (k && k[0]) return k[0]
                      return u
                    }), ...payments]
                  })
                }
                return a
              })
            })
          }
        } else {
          let title = data?.title
          if (title) {
            this.setState({
              titles: this.state.titles?.map(a => {
                if (a._id === title) {
                  return ({
                    ...a, payments: a.payments.find(a => a._id === data._id) ? [...a.payments].map((u) => {
                      if (u._id === data._id) return data
                      return u
                    }) : [...a.payments, data]
                  })
                }
                return a
              })
            })
          }
        }
        break
      }
      case 'sale': {
        if (data instanceof Array) {
          let title = data?.[0].title
          if (title) {
            let sales = data.filter(b => b.title === title)
            this.setState({
              titles: this.state.titles?.map(a => {
                if (a._id === title) {
                  return ({
                    ...a, sales: [...[...a.sales].map(u => {
                      let k = sales.splice(sales.findIndex(b => b._id === u._id), 1)
                      if (k && k[0]) return k[0]
                      return u
                    }), ...sales]
                  })
                }
                return a
              })
            })
          }
        } else {
          let title = data?.title
          if (title) {
            this.setState({
              titles: this.state.titles?.map(a => {
                if (a._id === title) {
                  return ({
                    ...a, sales: a.sales.find(a => a._id === data._id) ? [...a.sales].map((u) => {
                      if (u._id === data._id) return data
                      return u
                    }) : [...a.sales, data]
                  })
                }
                return a
              })
            })
          }
        }
        break
      }
      case 'sale-removal': {
        let title = data?.title
        if (title) {
          this.setState({
            titles: this.state.titles?.map(a => {
              if (a._id === title) {
                return ({ ...a, sales: a.sales.filter(b => b._id !== data._id) })
              }
              return a
            })
          })
        }
        break
      }
      case 'payment-removal': {
        let title = data?.title
        if (title) {
          this.setState({
            titles: this.state.titles?.map(a => {
              if (a._id === title) {
                return ({ ...a, payments: a.payments.filter(b => b._id !== data._id) })
              }
              return a
            })
          })
        }
      }
      default: return
    }
  }
  initial = () => {
    if (this.initing) return
    this.initing = true
    if (!window.ws && this.state.auth) window.newSocket()
    let proms = []
    if (this.state.auth) proms.push(this.getTitleList)
    if (this.state.auth) proms.push(this.getCompanyList)
    Promise.allSettled(proms.map(u => u())).then(() => {
      this.initing = false
      let state = { inited: true }
      if (this.state.auth) state.sidebarContents = (this.state.titleList?.sort((a, b) => window.nameCompare(a.title, b.title)).map((u) => ({ name: `${u.title}${u.titleAKA ? ` - ${u.titleAKA}` : ''}`, link: `/title?_id=${u._id}`, icon: <i className="fas fa-film fa-1x"></i> })) || [])
      this.setState(state)
    }).catch(e => {
      this.initing = false
      window.flash(e)
      window.logOut()
      this.setState({ inited: true })
    })
  }
  getCompanyList = () => {
    return new Promise((res, rej) => {
      if (this.gettingCompanies) return rej('GETTING ALREADY')
      this.gettingCompanies = true
      this.request('/company-list', 'post').then(({ companies }) => {
        this.gettingCompanies = false
        this.setState({ companyList: companies }, () => res())
      }).catch(e => {
        this.gettingCompanies = false
        rej(e)
      })
    })
  }
  getTitles = () => {
    return new Promise((res, rej) => {
      if (this.gettingTitles) return rej('GETTING ALREADY')
      this.gettingTitles = true
      this.request('/titles', 'post').then(({ titles }) => {
        this.gettingTitles = false
        this.setState({ titles }, () => res())
      }).catch(e => {
        this.gettingTitles = false
        rej(e)
      })
    })
  }
  getTitleList = () => {
    return new Promise((res, rej) => {
      this.request('/title-list', 'post').then(({ titles }) => {
        this.setState({ titleList: titles }, () => res())
      }).catch(e => rej(e))
    })
  }
  getTitle = (_id, fresh) => {
    return new Promise((res, rej) => {
      if (!_id) return rej('missing _id')
      if (!fresh) {
        let title = this.state.titles.find(a => a._id === _id)
        if (title) return res(title)
      }
      this.request(`/title?_id=${_id}`, 'post').then(({ title }) => {
        if (title) {
          try {
            this.setState({
              titles: this.state.titles.find(a => a._id === _id) ? [...this.state.titles].map(u => {
                if (u._id === _id) return title
                return u
              }) : [...this.state.titles, title]
            }, () => {
              return res(title)
            })
          } catch (e) {
            rej(e)
          }
        } else {
          return rej('Unable to locate title')
        }
      }).catch(e => {
        window.logOut()
        rej(e)
      })
    })
  }
  updateTitle = (_id, changes) => {
    return new Promise((res, rej) => {
      if (!changes || typeof changes !== 'object') return rej('missing changes')
      let title = this.state.titles.find(a => a._id === _id)
      if (!title) {
        return rej('Unable to locate title')
      } else {
        let updated = { ...title, ...changes }
        this.request('/update-title', 'post', {title: updated}).then(r => {
          this.setState({
            titles: [...this.state.titles].map(u => {
              if (u._id === _id) return updated
              return u
            })
          }, () => {
            return res(updated)
          })
        }).catch(e => rej(e))
      }
    })
  }
  queries() {
    let p = (window.location?.search ? window.location.search.substr(1) : '').split('=')
    let ar = []
    let pageQueries = []
    for (let i = 0; i < p.length; i++) ar.push(...p[i].split('&'))
    for (let i = 0; i < ar.length; i += 2) {
      let k = ar[i]
      let v = ar[i + 1]
      if (k && v) pageQueries.push({ key: k, value: v })
    }
    let o = {}
    for (let i = 0; i < pageQueries.length; i++) o[pageQueries[i].key] = pageQueries[i].value
    return o
  }
  cookies() {
    return document.cookie?.split('; ').reduce((a, b) => {
      if (b) {
        let c = b.split('=')
        a[c[0]] = c[1]
      }
      return a
    }, {})
  }
  request = (page, method, data) => {
    return new Promise((res, rej) => {
      let options = {
        method: method ? method : 'GET',
        headers: { authorization: this.state.auth, 'Content-Type': 'application/json' }
      }
      if (data && method && method !== 'GET') options.body = data && typeof data === 'object' ? JSON.stringify(data) : data
      fetch(window.API + page, options).then(result => result.json()).then(result => {
        if (result.error) return rej(result.message || result)
        return res(result)
      }).catch(e => { rej(e) })
    })
  }
  render() {
    if (!this.state.inited) return <div className="App">
      <div className="main">
        <div className="b1">
          <h3>Loading Data<DotDot /></h3>
          <Spinner />
        </div>
      </div>
    </div>
    // if (!this.state.auth || !this.state.userID || this.state.auth === 'undefined' || this.state.userID === 'undefined') return <><PageTitle title={'Login'}/><LogIn titles={this.state.titleList} /></>
    let title = (this.state.title) ? this.state.titles?.find(a => a._id === this.state.title) : null
    return (
      <div className="App">
        {this.state.auth && this.state.userID && this.state.type !== 'title' ? <Header name={(this.state.userID && this.state.type === 'title') ? (this.state.titles?.find(a => a._id === this.state.userID)?.title || this.state.titles?.[0]?.title || 'TITLE') : this.state.userID ? this.state.username || 'USER' : 'USER NAME'} titles={this.state.titleList || []}></Header> : <></>}
        <div id="main" className={this.state.userID && this.state.auth ? (this.state.sidebarContents?.length < 1 || this.state.type === 'title' ? 'barred' : '') + (this.state.type === 'title' ? ' titled' : '') : ''}>
          {this.state.sidebarContents?.length > 0 && this.state.type !== 'title' && <div id="sidebar">
            {this.state.sidebarContents.map((data, key) => (<Link key={key} to={data.link}><div className={"b2 jfs sidebarButton" + (this.state.location + (this.state.queries['_id'] ? `?_id=${this.state.queries['_id']}` : '') === data.link ? ' sidebarActive' : '')}>
              <div className="sidebarIcon">{data.icon}</div>
              <div className="sidebarContent b2 wrap jfe">
                <button className={'sb shine ' + (this.state.location === data.link ? 'activebutton' : '')}>{data.name}</button>
              </div>
            </div></Link>))}
          </div>}
          {this.state.auth && this.state.userID && this.state.showMobileHeader && <div id="mobileMenuCont" onClick={e => {
            if (e.target.id === 'mobileMenuCont') this.setState({ showMobileHeader: false })
          }}>
            <div id="mobileMenu">
              {/* <div id="topAccountOptions" className="b2" style={{ color: 'var(--l)' }}>
                <i style={{ color: 'var(--p)' }} className="far fa-user fa-2x"></i>
                <span onClick={() => {
                  if (!this.state.openMenu) this.setState({ openMenu: true })
                }}>{this.props.name}</span>
                <div className="rel"><i onClick={() => {
                  if (!this.state.openMenu) this.setState({ openMenu: true })
                }} style={{ color: 'var(--p)' }} className="fas fa-angle-down"></i>
                  <div id="floatOpt" className={this.state.openMenu ? '' : 'hidden'}>
                  </div>
                </div>
              </div> */}
              <button onClick={window.logOut}>Log Out</button>
              {this.state.sidebarContents?.map((data, key) => (<div className="b1" onClick={() => this.setState({ showMobileHeader: false })}><Link key={key} to={data.link}><div className={"b2 jfs sidebarButton" + (this.state.location + (this.state.queries['_id'] ? `?_id=${this.state.queries['_id']}` : '') === data.link ? ' sidebarActive' : '')}>
                <div className="sidebarIcon">{data.icon}</div>
                <div className="sidebarContent b2 wrap jfe">
                  <button className={'sb shine ' + (this.state.location === data.link ? 'activebutton' : '')}>{data.name}</button>
                </div>
              </div></Link></div>))}
            </div>
          </div>}
          <div id="content">
            <Switch>
              <Route exact path="/">
                {this.state.auth && this.state.userID ? <>
                  <PageTitle title={'Dashboard'} />
                  <div className="b1 jfs afs" style={{ padding: '10px' }}>
                    <h1>Choose a Title</h1>
                    <div className="c3">
                      {this.state.titleList?.length > 0 ? this.state.titleList.sort((a, b) => window.nameCompare(a.title, b.title)).map((u, i) => (<div className="selectTitle" key={i} onClick={() => { this.setState({ title: u._id }); window.redirect(`/title?_id=${u._id}`) }}>{u.title}{u.titleAKA && ` - ${u.titleAKA}`}</div>)) : <h3>No titles available to view.</h3>}
                    </div>
                  </div>
                </> : <> <PageTitle title={'Login'} /><LogIn titles={this.state.titleList} /></>}
              </Route>
              <Route exact path="/title">
                {title ? <><PageTitle title={title.title || 'TITLE'} /><Title title={title}></Title></> : <div className="b1"><h3>Loading Title<DotDot /></h3><Spinner /></div>}
              </Route>
              <Route exact path="/login"><PageTitle title={'Login'} /><LogIn titles={this.state.titleList} /></Route>
              <Route exact path="/reset-password"> <PageTitle title={'Reset Password'} /><ResetPass /></Route>
              <Route path="*">{!this.state.auth ? <><PageTitle title={'Login'} /><LogIn titles={this.state.titleList} /></> : <><PageTitle title={'Page Not Found'} /><h1>404 Page Not Found</h1></>}</Route>
            </Switch>
          </div>
        </div>
        {this.state.auth && this.state.userID && <div id="footer">{this.state.type === 'title' && <button onClick={e => window.logOut()}>Log Out</button>}<span>© {new Date().getFullYear()} Octane Multimedia LLC</span></div>}
      </div>
    );
  }
}

export default withRouter(App);