import React, { Component } from 'react'
import { Table as T } from 'antd';
import { itemRender, onShowSizeChange } from "./paginationfunction"
class Table extends Component {
    state = {}
    componentDidMount() {
    }
    componentDidUpdate() {
    }
    handleTableChange(e) {

    }
    render() {
        const { data, columns, mainKey, rowSelection } = this.props
        /*columns: [{
            title: 'Name',
            dataIndex: 'name',
              sorter: (a, b) => a.name.length - b.name.length,
               render: (text, record) => (
          <>{text}</>
        ),
          }]
          data: [
            {ITEM}
          ]
          */
        return (<div className="table-responsive">
            <T
              {...this.props}
                rowSelection={rowSelection}
                pagination={{
                    total: data?.length || 0,
                    showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                    showSizeChanger: true, onShowSizeChange: onShowSizeChange, itemRender: itemRender
                }}
                style={{overflowY: 'visible', overflowX: 'auto'}}
                columns={columns}
                bordered
                dataSource={data}
                rowKey={record => mainKey ? record[mainKey] : record['_id']}
                onChange={this.handleTableChange}
                className="table"
            />
        </div>)
    }
}
export default Table