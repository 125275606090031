import './secure-transfers'
import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css'
import "./antdstyle.css"
import './css/min.css'
import './index.css';
import './icons'
import './logos'
import './ws'
import App from './App';
import ArrayDisplay from './display/arraydisplay'
import { BrowserRouter as Router } from 'react-router-dom';
window.DEV = false;
window.API = window.DEV ? 'http://localhost:3778' : 'https://app.octanemultimedia.com'
window.WSURI = window.DEV ? 'ws://localhost:2801' : 'wss://ws.crm.octanemultimedia.com'
window.redirect = link => window.app.props.history.push(link)
window.nameCompare = (a,b) => {
  if (!a && b) return -1
  if (a && !b) return 1
  if (!a && !b) return 0
  if (typeof a !== 'string' && typeof b !== 'string') return 0
  if (typeof a === 'string' && typeof b !== 'string') return 1
  if (typeof a !== 'string' && typeof b === 'string') return -1
  if (a.toLowerCase() < b.toLowerCase()) {
    return -1
  } else if (a.toLowerCase() === b.toLowerCase()) {
    return 0
  } else {
    return 1
  }
}
window.makeLink = link => {
  if (link.includes('http')) return link
  return 'http://' + link
}
window.territories = [
  'Africa',
  'Australia / NZ',
  'Baltics',
  'Benelux',
  'Brazil',
  'Brunei',
  'Bulgaria',
  'Cambodia',
  'Central Asia',
  'China',
  'CIS',
  'Czech Rep',
  'East Europe',
  'Yugoslavia',
  'France',
  'Germany',
  'Greece',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Israel',
  'Italy',
  'Japan',
  'Latin America',
  'Malaysia',
  'Middle East',
  'Mexico',
  'Mongolia',
  'Pakistan',
  'Pan Asia',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Scandinavia',
  'Singapore',
  'South Africa',
  'South Korea',
  'Southeast Asia',
  'Spain',
  'Taiwan',
  'Thailand',
  'Turkey',
  'Ukraine',
  'UAE',
  'UK',
  'USA',
  'Vietnam'
]
window.fok = (obj, keys = []) => Object.entries(obj).reduce((a, b) => {
  if (!keys.includes(b[0])) a[b[0]] = b[1]
  return a
}, {})
window.index = (ar, page, s) => {
  let a = []
  if (ar.length <= s) return ar
  if (ar.length > s) {
    if (ar.length - s * page >= 0) {
      for (let i = s * page; i < s * (page + 1); i++) if (ar[i]) a.push(ar[i])
    } else {
      for (let z = ar.length - (ar.length % s) - 1; z < ar.length; z++) if (ar[z]) a.push(ar[z])
    }
  }
  return a
}
window.getCompany = b => b && typeof b === 'string' ? window.app.state.companyList?.find(a => a._id === b)?.name || b : ''
window.parseKey = (string) => {
  let a = string?.split('') || []
  let f = []
  let numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
  let uppercase = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]
  let delimiter = ['-', '_']
  let preps = ['the', 'a', 'an', 'of', 'in']
  for (let i = 0; i < a.length; i++) {
    if (i === 0 && a[i] === '$') continue
    if (i === 0 && !delimiter.includes(a[i])) {
      f.push(a[i].toUpperCase())
    } else {
      if (delimiter.includes(a[i])) {
        f.push(' ')
      } else if (numbers.includes(a[i]) && !numbers.includes(a[i - 1])) {
        f.push(' ', a[i])
      } else if (uppercase.includes(a[i])) {
        f.push(' ', a[i])
      } else {
        f.push(a[i])
      }
    }
  }
  return f.join('').split(' ').map((u, i) => {
    if (preps.includes(u.toLowerCase()) && i > 0) {
      return u.toLowerCase()
    } else {
      return u
    }
  }).join(' ').trim()
}
window.parseValue = value => {
  let type = value instanceof Array ? 'array' : value instanceof Date ? 'date' : typeof value
  switch (type) {
    case 'number': return window.parseNumber(value)
    case 'array': return <ArrayDisplay items={value} />
    case 'object': return JSON.stringify(value)
    case 'string': return value
    case 'boolean': return window.bool(value)
    case 'date': return window.formatDate(value)
    case 'undefined': return ''
    default: return JSON.stringify(value)
  }
}
window.destructureObject = u => {
  if (!u) return u
  let k = Object.keys(u)
  let o = {}
  for (let i = 0; i < k.length; i++) {
    if (typeof u[k[i]] !== 'object') {
      o[k[i]] = u[k[i]]
    } else if (u[k[i]]) {
      let { type, value } = u[k[i]]
      let v
      switch (type) {
        case 'object': {
          v = typeof value === 'object' ? value : typeof value === 'string' ? (() => {
            try {
              return JSON.parse(value)
            } catch (e) {
              return new Object(value)
            }
          })() : {}
          break
        }
        case 'array': {
          v = Array.from(value)
          break
        }
        case 'date': {
          v = new Date(value)
          break
        }
        case 'number': {
          v = window.parseNumber(value)
          break
        }
        case 'string': {
          v = typeof value === 'string' ? value : JSON.stringify(value)
          break
        }
        default: {
          v = value
        }
      }
      o[k[i]] = v
    } else {
      o[k[i]] = undefined
    }
  }
  return o
}
window.capitalize = (string) => {
  if (typeof string !== 'string') return string
  let a = string.split('')
  if (a && a[0]) a[0] = a[0].toUpperCase()
  return a.join('')
}
window.bool = value => value ? 'Yes' : 'No'
window.currency = num => num.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
window.parseNumber = v => {
  let a = parseFloat(v)
  if (!isNaN(a)) return a
  return 0
}
window.filterList = ['__title', '__model', '_t', '_u', '_v', 'metadata', '_id', 'title']
window.logOut = () => {
  localStorage.removeItem('type')
  localStorage.removeItem('userID')
  localStorage.removeItem('auth')
  window.location = window.API+'/logout-cookie'
}
window.formatDate = (date) => {
  let d = date && typeof date.getTime === 'function' ? date : new Date(date)
  let month = d.getMonth() + 1
  let day = d.getDate()
  return `${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}-${d.getFullYear()}`
}
window.parentClick = (e) => {
  e.preventDefault()
  e.stopPropagation()
  e.target.parentElement?.click()
}
window.sum = array => {
  let a = 0;
  if (!array || array.length < 1) return a
  for (let i = 0; i < array.length; i++) if (typeof array[i] === 'number') a += array[i]
  return a
}
window.request = (page, method, data) => {
  return new Promise((res, rej) => {
    let options = method ? { method, headers: { 'Content-Type': 'application/json', authorization: localStorage.getItem('auth'), userid: localStorage.getItem('userID') } } : null
    if (method !== 'GET' && data) options.body = JSON.stringify(data)
    fetch(window.API + page, options).then(result => result.json()).then(result => {
      console.log(page, method, data, result)
      if (result.error) return rej(result.message || JSON.stringify(result))
      return res(result)
    }).catch(e => rej(e))
  })
}
window.flash = (message, time = 3000) => {
  let div = document.createElement('div')
  div.innerHTML = message
  div.className = 'flasher'
  document.querySelector('body').prepend(div)
  setTimeout(() => div.remove(), time)
}
ReactDOM.render(
  <React.StrictMode><Router><App auth={localStorage.getItem('auth')} userID={localStorage.getItem('userID')} /></Router> </React.StrictMode>,
  document.getElementById('root')
);