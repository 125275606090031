import React, { useState, useMemo } from 'react'
import Table from './display/table'
import Accordian from './display/accordian'
import Timer from './display/timer'
import Report from './report'
import ArrayDisplay from './display/arraydisplay'
import Modal from './display/modal'
function Title(props) {
    let [state, setState] = useState({ view: 'accounting', allDate: true, dateA: null, dateB: null })
    let { title } = props
    let USReleaseDate = title.USReleaseDate
    let curDate = useMemo(() => new Date())
    let dateFrameOptions = useMemo(() => {
        const quarters = ['Q1', 'Q2', 'Q3', 'Q4']
        let quarterMap = {}
        let getQuarterYear = (quarter, year) => {
            let d = new Date()
            let s = new Date()
            d.setFullYear(year)
            s.setFullYear(year)
            switch (quarter) {
                case 'Q1': {
                    d.setMonth(0)
                    d.setDate(1)
                    s.setMonth(3)
                    s.setDate(1)
                    break
                }
                case 'Q2': {
                    d.setMonth(3)
                    d.setDate(1)
                    s.setMonth(6)
                    s.setDate(1)
                    break
                }
                case 'Q3': {
                    d.setMonth(6)
                    d.setDate(1)
                    s.setMonth(9)
                    s.setDate(1)
                    break
                }
                case 'Q4': {
                    d.setMonth(9)
                    d.setDate(1)
                    s.setMonth(12)
                    s.setDate(1)
                    break
                }
                default: {
                    break
                }
            }
            let q = `${quarter} ${year}`
            quarterMap[q] = { dateA: d, dateB: s }
            return q
        }
        let startDate = USReleaseDate ? new Date(USReleaseDate) : new Date()
        let startYear = startDate.getFullYear()
        let startQuarter = quarters[Math.floor(startDate.getMonth() / 3)]
        let endDate = curDate
        let endYear = endDate?.getFullYear()
        let endQuarter = quarters[Math.floor(endDate.getMonth() / 3)]
        let years = [...new Array(endYear - startYear + 1).fill(0).map((u, i) => startYear + i)]
        let options = years.map(u => {
            let beg = startYear === u
            let end = endYear === u
            let i = beg ? quarters.indexOf(startQuarter) : end ? quarters.indexOf(endQuarter) : 1000
            return beg ? quarters.filter((a, index) => index >= i).map(quarter => ({ label: `${quarter} ${u}`, value: getQuarterYear(quarter, u) })) : end ? quarters.filter((a, index) => index <= i).map(quarter => ({ label: `${quarter} ${u}`, value: getQuarterYear(quarter, u) })) : quarters.map(quarter => ({ label: `${quarter} ${u}`, value: getQuarterYear(quarter, u) }))
        }).reduce((a, b) => a.concat(b), []).map((z, i) => (<option key={i} value={z.value}>{z.label}</option>))
        return { options, map: quarterMap }
    }, [USReleaseDate, curDate])
    let timer = useMemo(() => <Timer since={true} endText={' '} expires={new Date(USReleaseDate)} />, [USReleaseDate])
    if (!title) return <></>
    let checkDate = time => {
        if (state.allDate) return true
        let x = state.dateA ? new Date(state.dateA).getTime() : null
        let y = state.dateB ? new Date(state.dateB).getTime() : null
        let t = new Date(time).getTime()
        return (t && ((x && y) ?
            ((t > x) && (t < y)) :
            x ?
                (t > x) :
                y ?
                    (t < y) :
                    true))
    }
    let { sales = [], payments = [], deals = [] } = title
    let expenses = payments.filter(u => u.amount < 0) || []
    let income = payments.filter(u => u.amount >= 0) || []
    let hideMeta = ['_id', '__v', 'title', 'id',]
    let hideAgree = []
    let salesNum = window.sum(sales.filter(a => ((state.dateA || state.dateB) ? checkDate(a.date) : true)).map(u => (u.received_amount)))
    let distroNum = window.sum(income.filter(a => ((state.dateA || state.dateB) ? checkDate(a.date) : true)).map(u => (u.income)))
    let reducedExpenses = Object.entries(Object.entries(expenses.filter(a => ((state.dateA || state.dateB) ? checkDate(a.date) : true)).reduce((a, b) => {
        if (!b.category) {
            a['Miscellaneous Expenses'].push(b)
        } else if (!a[b.category]) {
            a[b.category] = [b.amount * -1]
        } else {
            a[b.category].push(b.amount * -1)
        }
        return a
    }, { 'Miscellaneous Expenses': [] })).reduce((a, [k, v]) => {
        a[k] = window.sum(v)
        return a
    }, {}))
    let totalIncome = salesNum + distroNum
    let salesFee = 0
    let distroFee = 0
    let reservedIncome = 0
    if (title.agreement?.salesFeePercentage) {
        let amount = totalIncome * (title.agreement.salesFeePercentage || 0) / 100
        reducedExpenses.push(['Sales Fee', amount])
        salesFee = amount
    }
    if (title.agreement?.distributionFeePercentage) {
        let amount = totalIncome * (title.agreement.distributionFeePercentage || 0) / 100
        reducedExpenses.push(['Distribution Fee', amount])
        distroFee = amount
    }
    if (title.agreement.incomeReservesPercentage) {
        let amount = totalIncome * (title.agreement.incomeReservesPercentage || 0) / 100
        reducedExpenses.push(['Income Reserves', amount])
        reservedIncome = amount
    }
    let expenseNum = window.sum(expenses.filter(a => ((state.dateA || state.dateB) ? checkDate(a.date) : true)).map(u => (u.amount * -1))) + salesFee + distroFee + reservedIncome
    let allPastPayments = window.sum(title.producerPayments.map(a => a.amount))
    let totalOwed = ((salesNum + distroNum) - (expenseNum)) - allPastPayments
    return (<>
        {state.expanded && <Modal dismiss={() => setState({ ...state, expanded: false })}>
            <div className="b1">
                <img className="title-poster-expanded" src={title.keyArt} />
            </div>
        </Modal>}
        <div className="b1 jfs">
            <div className="c2" style={{ background: 'var(--l)' }}>
                <div className="b2 jfs">
                    {title.keyArt && <img onClick={() => setState({ ...state, expanded: true })} className="title-poster" src={title.keyArt} />}
                    <div className="b1 jfs afs">
                        <h1>{title.title}</h1>
                        {title.metadata?.trailer && <a href={title.metadata.trailer} target="_blank" referrer={'noreferrer'}><span className="pointer">Watch Trailer!</span></a>}
                        {title.metadata?.trailer && <a href={title.metadata.screener} target="_blank" referrer={'noreferrer'}><span className="pointer">Watch Screener!</span></a>}
                        <span style={{ textAlign: 'left' }}>{title.USReleaseDate && <><strong style={{ textAlign: 'left' }}>{new Date(title.USReleaseDate).getTime() - new Date().getTime() < 0 ? 'Released Domestically' : 'Count Down to Domestic Release'}:</strong> {timer}</>} </span>
                        {window.app.state.type === 'title' && <span style={{ color: 'var(--p)', cursor: 'pointer', marginLeft: '10px' }} onClick={e => window.logOut()}>Log Out</span>}
                    </div>
                </div>
                <div id="titleTabs" className="b2 wrap jfe" style={{ width: 'auto' }}>
                    <h2 className={state.view === 'info' ? 'activeTab' : ''} onClick={() => setState({ ...state, view: 'info', report: null })}>FILM INFO</h2>
                    <h2 className={state.view === 'accounting' ? 'activeTab' : ''} onClick={() => setState({ ...state, view: 'accounting', report: null })}>ACCOUNTING</h2>
                    <h2 className={state.view === 'deal' ? 'activeTab' : ''} onClick={() => setState({ ...state, view: 'deal', report: null })}>DEAL INFO</h2>
                    <h2 className={state.view === 'reporting' ? 'activeTab' : ''} onClick={() => setState({ ...state, view: 'reporting', report: null })}>GENERATE REPORT</h2>
                </div>
            </div>
            {state.view === 'info' && <div className="b1 jfs afs pad-10">
                <h2>Film Info</h2>
                <div className="c2">
                    <Accordian header="Hard Drive" ><div className="b1 jfs afs tInfo">
                        <span style={{ textAlign: 'left' }}><strong>Hard Drive Received:</strong> {title.hardDrive?.dateReceived ? window.formatDate(title.hardDrive.dateReceived) : 'Not Received'}</span>
                        {title.hardDrive && <span><h4>Hard Drive Contents:</h4> {title.hardDrive?.checkList ? <ul style={{ listStyle: 'none' }}>{Object.entries(title.hardDrive.checkList).map(([key, value], i) => (<li key={i}><strong>{key}:</strong> {window.bool(value)}</li>))}</ul> : 'Not Received'}</span>}
                    </div></Accordian>
                    <Accordian header="Metadata">
                        <div className="b1 jfs afs tInfo">
                            <h4>Metadata</h4>
                            {title.metadata && Object.entries(window.fok(title.metadata, hideMeta)).filter(([key, value]) => typeof value !== 'undefined' && value && (value instanceof Array ? value.length > 0 : true)).map(([key, value], i) => (<span key={i}><strong>{window.parseKey(key)}:</strong> {window.parseValue(value) ?? JSON.stringify(value)}</span>))}
                        </div>
                    </Accordian>
                    {/* PR LIST */}
                    <Accordian header="PR">
                        <div className="b1 tInfo">
                            <ul className="pr-list">
                                {typeof title.PRList === 'object' && Object.entries(title.PRList).filter(([key, value]) => value).map(([key, value], i) => !['admin', 'dev'].includes(window.app.state.type) ? (<li key={i} className="pr-list-item"><a target="_blank" href={window.makeLink(value)}><strong>{key}</strong></a></li>) : (<li key={i} className="pr-list-item"><strong>{key}:</strong> <a target="_blank" href={window.makeLink(value)}>{value}</a> <button className="pr-list-button" onClick={() => {
                                    setState({ updatingPR: true })
                                    window.app.updateTitle(title._id, { PRList: window.fok(title.PRList, key) }).then(r => {
                                        setState({ ...state, updatingPR: false })
                                    }).catch(e => setState({ ...state, updatingPR: false }))
                                }}>{state.updatingPR ? 'Updating...' : 'Remove'}</button></li>))}
                            </ul>
                            <span className="warn">{state.PRWarn}</span>
                            <div className="b1">
                                <input className="align-text-left w100" type="text" placeholder="Name" value={state.currentPRName} onChange={e => setState({ ...state, currentPRName: e.target.value })}></input>
                                <textarea className="align-text-left w100" type="text" placeholder="Link" value={state.currentPRInput} onChange={e => setState({ ...state, currentPRInput: e.target.value })}></textarea>
                                <button onClick={e => {
                                    if (state.currentPRInput, state.currentPRName) {
                                        setState({ updatingPR: true })
                                        window.app.updateTitle(title._id, { PRList: title.PRList ? { ...title.PRList, [state.currentPRName]: state.currentPRInput } : {} }).then(updated => {
                                            setState({ ...state, currentPRName: '', currentPRInput: '', updatingPR: false })
                                        }).catch(e => setState({ ...state, updatingPR: false, PRWarn: e }))
                                    } else {
                                        setState({ ...state, PRWarn: 'Please enter a name and value' })
                                        setTimeout(() => setState({ ...state, PRWarn: '' }), 3000)
                                    }
                                }}>{state.updatingPR ? 'Updating...' : 'Add (+)'}</button>
                            </div>
                        </div>
                    </Accordian>
                    <Accordian header="Marketing">
                        <div className="b1 jfs afs tInfo">

                        </div>
                    </Accordian>
                    <Accordian header="Agreement Info">
                        <div className="b1 jfs afs tInfo">
                            <h4>Agreement</h4>
                            {title.agreement && Object.entries(window.fok(title.agreement, hideAgree)).filter(([key, value]) => typeof value !== 'undefined' && value && (value instanceof Array ? value.length > 0 : true)).map(([key, value], i) => (<span key={i}><strong>{window.parseKey(key)}:</strong> {window.parseValue(value) ?? JSON.stringify(value)}</span>))}
                        </div>
                    </Accordian>
                </div>
            </div>}
            {state.view === 'deal' && <div className="b1 jfs afs pad-10">
                <h2>Deals</h2>
                <div className="c1" style={{ padding: '10px' }}>
                    <Table
                        className={'tInfo'}
                        columns={[
                            /*                             {
                                                            title: `Name`,
                                                            dataIndex: "name",
                                                            render: (text, record) => (
                                                                <>{text}</>
                                                            ),
                                                            sorter: (a, b) => window.nameCompare(a.name, b.name),
                                                        }, */
                            {
                                title: `Buyer`,
                                dataIndex: "company",
                                render: (text, record) => (
                                    <>{window.getCompany(text)}</>
                                ),
                                sorter: (a, b) => window.nameCompare(window.getCompany(a.company), window.getCompany(b.company)),
                            },
                            {
                                title: `Territories`,
                                dataIndex: "territories",
                                render: (text, record) => (
                                    <>{<ArrayDisplay items={record.territories} />}</>
                                )
                            },
                            { title: 'Received Amount', dataIndex: 'value', render: (text, record) => <>${window.currency(record.value || 0)}</>, sorter: (a, b) => a.value - b.value },
                            {
                                title: 'Rights Purchased', dataIndex: 'rights', render: (text, record) => (
                                    <>{<ArrayDisplay items={record.rights} />}</>
                                )
                            },
                            /*  {
                                 title: `Date`,
                                 dataIndex: "date",
                                 render: (text, record) => (
                                     <>{window.formatDate(record.date)}</>
                                 ),
                                 sorter: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
                             } */
                        ]}
                        mainKey={'_id'}
                        data={deals}
                    />
                </div>
            </div>}
            {state.view === 'accounting' && <div className="b1 jfs afs pad-10">
                <h2>Summary</h2>
                <span>{(state.dateA || title.contractStartDate) ? `Starting ${state.dateA ? window.formatDate(state.dateA) : title.contractStartDate ? window.formatDate(title.contractStartDate) : ''}` : ''} {state.dateB ? `${state.dateA ? 't' : 'T'}hrough ${window.formatDate(state.dateB)}` : ''} <button onClick={() => setState({ ...state, setDate: !state.setDate })}>{state.setDate ? "Set" : 'Change'} Dates</button></span>
                {state.setDate && <div className="b1">
                    <label>Set Date Range:</label>
                    <select onChange={(e) => {
                        let { value } = e.target;
                        if (!value) return setState({ ...state, dateA: null, dateB: null, quarterInputValue: value, allDate: true });
                        let { dateA, dateB } = dateFrameOptions.map[value]
                        setState({ ...state, dateA: new Date(dateA), dateB: new Date(dateB), allDate: false, quarterInputValue: value })
                    }} value={state.quarterInputValue || ''}>
                        <option value="">All Time</option>
                        {dateFrameOptions.options}
                    </select>
                    {/*                     <div className="c2 jfs">
                        <div className="b1 jfs afs">
                            <label className="homelabel">Start Date</label>
                            <input type="date" name="start" value={state.dateA} onChange={e => setState({ ...state, dateA: e.target.value, allDate: false })} className="fI dateInput"></input>
                        </div>
                        <div></div>
                        <div className="b1 jfs afs">
                            <label className="homelabel">End Date</label>
                            <input type="date" value={state.dateB} onChange={e => setState({ ...state, dateB: e.target.value, allDate: false })} name="end" className="fI dateInput"></input>
                        </div>
                        <div className="b2 jfs">
                            <label className="homelabel">All Dates</label>
                            <input className="checkInput" type="checkbox" checked={(!state.dateA && !state.dateB) || state.allDate} onChange={e => setState(e.target.checked ? { ...state, dateA: null, dateB: null, allDate: e.target.checked } : { ...state, allDate: e.target.checked })} name="all"></input>
                        </div>
                    </div> */}
                </div>}
                <div className="c1" style={{ padding: '10px' }}>
                    <div className="b1 jfs afs tInfo">
                        <span><strong>Sales:</strong> ${window.currency(salesNum)}</span>
                        <span><strong>Distribution:</strong> ${window.currency(distroNum)}</span>
                        <span className="totalled"><strong>Combined Income:</strong> ${window.currency(salesNum + distroNum)}</span>
                        {reducedExpenses.map(([k, v], i) => (<span key={i}><strong>{window.capitalize(k)}:</strong> ${window.currency(v)}</span>))}
                        <span className="totalled"><strong>Total Expenses:</strong> ${window.currency(Math.abs(expenseNum))}</span>
                        {(state.dateA || state.dateB) ? <>
                            <span className="totalled"><strong>Total Expenses:</strong> ${window.currency(expenseNum)}</span>
                        </> : <>
                            <span><strong>Past Payments:</strong> ${window.currency(allPastPayments)}  {title.lastPayout && <><strong>Last Payment Sent:</strong> {window.formatDate(title.lastPayout)}</>}</span>
                            <span className="totalled"><strong>Net Owed to Producer:</strong> ${window.currency(totalOwed > 0 ? totalOwed : 0)} {totalOwed > 0 && title.nextPayout && <><strong>Date to be Sent:</strong> {window.formatDate(title.nextPayout)}</>}</span>
                        </>}
                    </div>
                </div>
                <h2>Accounting</h2>
                <div className="c1" style={{ padding: '10px' }}>
                    <div className="b1 jfs">
                        <strong><span>Total Sales (${window.currency(window.sum(sales.map(u => u.received_amount)))})</span></strong>
                        <Table className={'tInfo'} columns={[
                            {
                                title: `Date Closed`,
                                dataIndex: "dateClosed",
                                render: (text, record) => (
                                    <>{record.dateClosed && window.formatDate(record.dateClosed)}</>
                                ),
                                sorter: (a, b) => new Date(a.dateClosed).getTime() - new Date(b.dateClosed).getTime(),
                            }, {
                                title: `Date Received`,
                                dataIndex: "date",
                                render: (text, record) => (
                                    <>{record.date && window.formatDate(record.date)}</>
                                ),
                                sorter: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
                            },
                            { title: 'Buyer', dataIndex: 'buyer', render: text => <>{window.getCompany(text)}</>, sorter: (a, b) => window.nameCompare(window.getCompany(a.buyer), window.getCompany(b.buyer)) },
                            { title: 'Territory', dataIndex: 'territory', sorter: (a, b) => window.nameCompare(a.territory, b.territory) },
                            {
                                title: 'Received Amount', dataIndex: 'received_amount', sorter: (a, b) => b.received_amount - a.received_amount, render: (text, record) => (
                                    <>${window.currency(record.received_amount)}</>
                                )
                            },
                            {
                                title: 'Agreement Amount', dataIndex: 'agreement_amount', sorter: (a, b) => b.agreement_amount - a.agreement_amount, render: (text, record) => (
                                    <>${window.currency(record.agreement_amount)}</>
                                )
                            }
                        ]} data={sales} mainKey={'_id'} />
                    </div>
                    <div className="b1 jfs">
                        <strong><span>Total Payments (${window.currency(window.sum(income.map(u => u.income)))})</span></strong>
                        <Table className={'tInfo'} columns={[
                            {
                                title: `Date Received`,
                                dataIndex: "dateReceived",
                                render: (text, record) => (
                                    <>{record.dateReceived && window.formatDate(record.dateReceived)}</>
                                ),
                                sorter: (a, b) => new Date(a.dateReceived).getTime() - new Date(b.dateReceived).getTime(),
                            },
                            {
                                title: `Date Entered`,
                                dataIndex: "date",
                                render: (text, record) => (
                                    <>{window.formatDate(record.date)}</>
                                ),
                                sorter: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
                            },
                            { title: 'Platform', dataIndex: 'platform', render: text => <>{window.getCompany(text)}</>, sorter: (a, b) => window.nameCompare(window.getCompany(a.platform), window.getCompany(b.platform)) },
                            title.agreement?.grossCorridorPercentage ? {
                                title: 'Gross Corridor', dataIndex: 'income', render: (text, record) => <>{`$${window.currency(title.agreement.grossCorridorPlatforms?.length ? (title.agreement.grossCorridorPlatforms.includes(record.platform) ? (record.income * (title.agreement.grossCorridorPercentage / 100)) : 0) : (record.income * (title.agreement.grossCorridorPercentage / 100)))}`}</>, sorter: (a, b) => b.grossCorridor - a.grossCorridor,
                            } : null,
                            {
                                title: 'Amount', dataIndex: 'income', render: (text, record) => (
                                    <>${window.currency(record.income)}</>
                                ), sorter: (a, b) => b.income - a.income
                            }
                        ].filter(a => a)} data={income} mainKey={'_id'} />
                    </div>
                    <div className="b1 jfs">
                        <strong><span>Total Expenses (-${window.currency(Math.abs(window.sum(expenses.map(u => u.income))))})</span></strong>
                        <Table className={'tInfo'} columns={[
                            {
                                title: `Date Entered`,
                                dataIndex: "date",
                                render: (text, record) => (
                                    <>{window.formatDate(record.date)}</>
                                ),
                                sorter: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
                            },
                            {
                                title: `Type`,
                                dataIndex: "category",
                                render: (text, record) => (
                                    <>{text}</>
                                ),
                                sorter: (a, b) => window.nameCompare(a.category, b.category),
                            },
                            // { title: 'Platform', dataIndex: 'platform', render: text => <>{window.getCompany(text)}</>, sorter: (a, b) => window.nameCompare(window.getCompany(a.platform), window.getCompany(b.platform)) },
                            {
                                title: 'Amount', dataIndex: 'income', render: (text, record) => (
                                    <>-${window.currency(Math.abs(record.income))}</>
                                ), sorter: (a, b) => b.income - a.income
                            }
                        ]} data={expenses} mainKey={'_id'} />
                    </div>
                    <div className="b1 jfs">
                        <strong><span>Total Payouts (${window.currency(Math.abs(window.sum(title.producerPayments?.map(u => u.amount) || [])))})</span></strong>
                        <Table className={'tInfo'} columns={[
                            {
                                title: `Date`,
                                dataIndex: "date",
                                render: (text, record) => (
                                    <>{window.formatDate(record.date)}</>
                                ),
                                sorter: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
                            },
                            {
                                title: 'Amount', dataIndex: 'amount', render: (text, record) => (
                                    <>${window.currency(Math.abs(record.amount))}</>
                                ), sorter: (a, b) => b.amount - a.amount
                            },
                            {
                                title: `Description`,
                                dataIndex: "description",
                                render: (text, record) => (
                                    <>{text}</>
                                ),
                                sorter: (a, b) => window.nameCompare(a.description, b.description),
                            }
                        ]} data={title.producerPayments} mainKey={'_id'} />
                    </div>
                </div>
            </div>}
            {state.view === 'reporting' && <div className="b1 jfs afs pad-10">
                <h2>Generate Report</h2>
                <GenerateReport report={state.report} sales={sales} expenses={expenses} payments={income} runReport={state => setState({ ...state, view: 'report', report: state })} />
            </div>}
            {state.view === 'report' && <div className="b1 jfs afs pad-10">
                <h2>View Report</h2><button onClick={() => setState({ ...state, view: 'reporting' })}>Close Report</button>
                <Report data={state.report} expenses={expenses} payments={income} sales={sales}></Report>
            </div>}
        </div>
    </>)
}
function GenerateReport(props) {
    let { sales = [], payments = [], expenses = [] } = props
    let [state, setState] = useState(props.report || {})
    let { type, allDate, dateA, dateB, aPlats, selectedBuyers = [], selectedTerritories = [], selectedPlatforms = [], aTers, aBuys } = state
    let runReport = () => props.runReport(state)
    return (<div className="b1">
        <div className="b1 jfs afs rel" style={{ padding: '20px' }}>
            <label>Report Type</label>
            <select className="fI selectInput" value={type} onChange={e => setState({ ...state, type: e.target.value, selectedBuyers: [], selectedPlatforms: [], selectedTerritories: [] })}>
                <option className="homeoption" value="">Choose Type</option>
                <option className="homeoption" value="sale-buyer">Sales by Buyer</option>
                <option className="homeoption" value="sale-territory">Sales by Territory</option>
                <option className="homeoption" value="payment">Payments</option>
                <option className="homeoption" value="expense">Expenses</option>
            </select>
            {type && <>
                <div className="c2 jfs">
                    {!allDate && <><div className="b1 jfs afs">
                        <label className="homelabel">Start Date</label>
                        <input type="date" name="start" value={dateA} onChange={e => setState({ ...state, dateA: e.target.value, allDate: false })} className="fI dateInput"></input>
                    </div>
                        <div></div>
                        <div className="b1 jfs afs">
                            <label className="homelabel">End Date</label>
                            <input type="date" value={dateB} onChange={e => setState({ ...state, dateB: e.target.value, allDate: false })} name="end" className="fI dateInput"></input>
                        </div></>}
                    <div className="b2 jfs">
                        <label className="homelabel">All Dates</label>
                        <input className="checkInput" type="checkbox" checked={(!dateA && !dateB) || allDate} onChange={e => setState({ ...state, allDate: e.target.checked })} name="all"></input>
                    </div>
                </div>
                {type === 'sale-buyer' ? <div style={{ marginTop: '20px' }} className="b1 jfs afs">
                    <h3>Select Buyers</h3>
                    {!aBuys && <div className="b2 jfs afs">{selectedBuyers.sort(window.nameCompare).map((u, i) => <label key={i}>{u.toUpperCase()}</label>)}</div>}
                    <div className="b2 jfs">
                        <label className="homelabel">All Buyers</label>
                        <input className="checkInput" type="checkbox" checked={aBuys} onChange={e => setState({ ...state, aBuys: e.target.checked })} name="all"></input>
                    </div>
                    {!aBuys && <div className="b1">{[...(sales?.reduce((a, b) => {
                        if (b.buyer && !a.includes(b.buyer)) a.push(b.buyer)
                        return a
                    }, []) || []), ...(window.app.state.companyList?.filter(a => a.crm === 'film-sales').map(u => u._id) || [])].sort((a, b) => window.nameCompare(window.getCompany(a), window.getCompany(b))).map((u, i) => u && (<div className="b2 jfs" key={i} name={u}>
                        <label>{window.getCompany(u).toUpperCase()}</label>
                        <input className="checkInput" type="checkbox" checked={selectedBuyers.find(a => a === u) || false} onChange={e => {
                            let name = e.target.getAttribute(['name'])
                            if (!name) return
                            let a = selectedBuyers.find(u => u === name)
                            if (a) {
                                setState({ ...state, selectedBuyers: [...selectedBuyers].filter(u => u !== name) })
                            } else {
                                setState({ ...state, selectedBuyers: [...selectedBuyers, name] })
                            }
                        }} name={u}></input>
                    </div>))}
                    </div>}
                </div> : type === 'expense' ? <div style={{ marginTop: '20px' }} className="b1 jfs afs">
                    {/* CHANGE THESE TO EXPENSE TYPES */}
                    <h3>Select Platforms</h3>
                    {!aPlats && <div className="b2 jfs afs">{selectedPlatforms.sort(window.nameCompare).map((u, i) => <label key={i}>{u.toUpperCase()}</label>)}</div>}
                    <div className="b2 jfs">
                        <label className="homelabel">All Platforms</label>
                        <input className="checkInput" type="checkbox" checked={aPlats} onChange={e => setState({ ...state, aPlats: e.target.checked })} name="all"></input>
                    </div>
                    {!aPlats && <div className="b1">{[...(expenses?.reduce((a, b) => {
                        if (b.platform && !a.includes(b.platform)) a.push(b.platform)
                        return a
                    }, []) || []), ...(window.app.state.companyList?.filter(a => a.crm === 'film-distribution').map(u => u._id) || [])].sort((a, b) => window.nameCompare(window.getCompany(a), window.getCompany(b))).map((u, i) => u && (<div className="b2 jfs" key={i} name={u}>
                        <label>{window.getCompany(u).toUpperCase()}</label>
                        <input className="checkInput" type="checkbox" checked={selectedPlatforms.find(a => a === u) || false} onChange={e => {
                            let name = e.target.getAttribute(['name'])
                            if (!name) return
                            let a = selectedPlatforms.find(u => u === name)
                            if (a) {
                                setState({ ...state, selectedPlatforms: [...selectedPlatforms].filter(u => u !== name) })
                            } else {
                                setState({ ...state, selectedPlatforms: [...selectedPlatforms, name] })
                            }
                        }} name={u}></input>
                    </div>))}
                    </div>}
                </div> : type === 'payment' ? <div style={{ marginTop: '20px' }} className="b1 jfs afs">
                    <h3>Select Platforms</h3>
                    {!aPlats && <div className="b2 jfs afs">{selectedPlatforms.sort(window.nameCompare).map((u, i) => <label key={i}>{u.toUpperCase()}</label>)}</div>}
                    <div className="b2 jfs">
                        <label className="homelabel">All Platforms</label>
                        <input className="checkInput" type="checkbox" checked={aPlats} onChange={e => setState({ ...state, aPlats: e.target.checked })} name="all"></input>
                    </div>
                    {!aPlats && <div className="b1">{[...(payments?.reduce((a, b) => {
                        if (b.platform && !a.includes(b.platform)) a.push(b.platform)
                        return a
                    }, []) || []), ...(window.app.state.companyList?.filter(a => a.crm === 'film-distribution').map(u => u._id) || [])].sort((a, b) => window.nameCompare(window.getCompany(a), window.getCompany(b))).map((u, i) => u && (<div className="b2 jfs" key={i} name={u}>
                        <label>{window.getCompany(u).toUpperCase()}</label>
                        <input className="checkInput" type="checkbox" checked={selectedPlatforms.find(a => a === u) || false} onChange={e => {
                            let name = e.target.getAttribute(['name'])
                            if (!name) return
                            let a = selectedPlatforms.find(u => u === name)
                            if (a) {
                                setState({ ...state, selectedPlatforms: [...selectedPlatforms].filter(u => u !== name) })
                            } else {
                                setState({ ...state, selectedPlatforms: [...selectedPlatforms, name] })
                            }
                        }} name={u}></input>
                    </div>))}
                    </div>}
                </div> : type === 'sale-territory' ? <div style={{ marginTop: '20px' }} className="b1 jfs afs">
                    <h3>Select Territories</h3>
                    {!aTers && <div className="b2 jfs afs">{selectedTerritories.sort(window.nameCompare).map((u, i) => <label key={i}>{u.toUpperCase()}</label>)}</div>}
                    <div className="b2 jfs">
                        <label className="homelabel">All Territories</label>
                        <input className="checkInput" type="checkbox" checked={aTers} onChange={e => setState({ ...state, aTers: e.target.checked })} name="all"></input>
                    </div>
                    {!aTers && <div className="b1">{[...(sales?.reduce((a, b) => {
                        if (b.territory && !a.includes(b.territory)) a.push(b.territory)
                        return a
                    }, []) || []), ...window.territories].sort(window.nameCompare).map((u, i) => u && (<div className="b2 jfs" key={i} name={u}>
                        <label>{u.toUpperCase()}</label>
                        <input className="checkInput" type="checkbox" checked={selectedTerritories.find(a => a === u) || false} onChange={e => {
                            let name = e.target.getAttribute(['name'])
                            if (!name) return
                            let a = selectedTerritories.find(u => u === name)
                            if (a) {
                                setState({ ...state, selectedTerritories: [...selectedTerritories].filter(u => u !== name), aTers: false })
                            } else {
                                setState({ ...state, selectedTerritories: [...selectedTerritories, name], aTers: false })
                            }
                        }} name={u}></input>
                    </div>))}
                    </div>}
                </div> : ''}
                <button style={{ marginTop: '7vh' }} onClick={() => runReport()}>Run Report</button>
            </>}
        </div>
    </div>)
}
export default Title