window.reconnect = 0
window.wsinitial = false
window.lastPing = null
window.lastPingTime = null
window.currentPing = 0
window.sendPing = () => {
  window.lastPing = Math.floor(Math.random() * 10000)
  window.lastPingTime = new Date().getTime()
  window.ws.sendData({ type: 'ping', data: window.lastPing })
}
window.checkws = () => {
  if (window.ws && window.ws.readyState !== WebSocket.OPEN) {
    window.newSocket().catch(e => { window.flash(`WS ERROR: ${JSON.stringify(e)}`) })
  } else {
    window.sendPing()
  }
}
window.checkauth = () => {
  if (window.ws && window.ws.readyState === WebSocket.OPEN && (window.app.state.auth && window.app.state.auth !== 'undefined')) window.ws.sendData({ type: 'auth', data: { method: 'check', auth: window.app.state.auth } })
}
window.runSocket = (e) => {
  let that
  try {
    that = JSON.parse(e)
  } catch (err) {
    try {
      that = JSON.parse(e.data)
    } catch (e) {
      return console.log(`RECEIVED DATA: ${JSON.stringify(e)}`)
    }
  }
  if (that && that.type) {
    let { type, data, error } = that
    const app = window.app
    switch (type) {
      case 'auth': {
        if (error) {
          window.flash(that.message || 'Issue with authenticating you, please sign in again')
          window.logOut()
        } else {
          app.setState({ authenticated: true })
        }
        break
      }
      case 'ping': {
        if (window.lastPing !== data) {
          window.sendPing()
        } else {
          window.currentPing = `Current Ping: ${new Date().getTime() - window.lastPingTime}ms`
        }
        break
      }
      default: {
        return app.handleSocket(type, data, error)
      }
    }
  }
}
window.socketWait = null
window.waitForSocketConnection = (socket, callback, data, timer = 1000) => {
  clearTimeout((window.socketWait))
  if (!callback || typeof callback !== 'function') return
  if (socket.readyState === 0) {
    window.socketWait = setTimeout(() => { window.waitForSocketConnection(socket, callback, data, timer) }, timer)
  } else if (socket.readyState === 1) {
    callback(data)
  } else {
    window.socketWait = window.waitForSocketConnection(socket, callback, data, timer)
  }
}
window.waitForAuth = (socket, callback, data, timer = 1000, rounds = 0) => {
  clearTimeout((window.socketWaitForAuth))
  if (!callback || typeof callback !== 'function' || rounds > 10) return
  if (!window.app.state.authenticated) {
    window.socketWaitForAuth = setTimeout(() => { window.waitForAuth(socket, callback, data, timer) }, timer, rounds + 1)
  } else {
    callback(data)
  }
}
window.newSocket = function () {
  return new Promise((res, rej) => {
    clearInterval(window.heartbeat)
    clearTimeout((window.socketWait))
    clearTimeout((window.socketWaitForAuth))
    try {
      window.ws = new WebSocket(window.WSURI)
      window.ws.onopen = function (e) { window.heartbeat = setInterval(() => { window.checkws() }, 60000); };
      window.waitForSocketConnection(window.ws, () => { if (window.app?.state?.auth && typeof window.app.state.auth !== 'undefined') window.ws.sendData({ type: 'auth', data: { auth: window.app.state.auth } }) })
      window.ws.onmessage = function incoming(data) { window.runSocket(data) }
      window.ws.sendData = (data) => {
        if (!window.app.state.authenticated && data.type !== 'auth') {
          window.waitForAuth(window.ws, data => { window.ws.send(JSON.stringify(data)) }, data)
        } else if (window.ws.readyState !== 1) {
          window.waitForSocketConnection(window.ws, data => { window.ws.send(JSON.stringify(data)) }, data)
        } else {
          window.ws.send(JSON.stringify(data))
        }
      }
      window.ws.onclose = () => {
        window.newSocket()
      }
      return res(true)
    } catch {
      if (!window.wsinitial) return rej('Error loading websocket')
      if (window.reconnect <= 10) {
        setTimeout(() => {
          window.reconnect++
          window.newSocket()
        }, window.reconnect * 3000)
      } else {
        return rej('Error reloading websocket. must be an issue on the backend.')
      }
    }
  })
}