export default function Modal({ children, dismiss = () => {} }) {
    return (
        <div className="modal">
            <div className="modal-container" onClick={(e) => {
                e.stopPropagation();
                if (e.target.isSameNode(e.currentTarget)) {
                    return dismiss()
                }
            }}>
                <div className="modal-content">
                    {children}
                </div>
            </div>
        </div>
    );
}